import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Twitter from 'tcweb-material-components/core/social/twitter'
import Linkedin from 'tcweb-material-components/core/social/linkedin'
import Icon from 'tcweb-material-components/core/icon'

import MenuItem from './menuItem'

const ITEM_GAP = '30px'
const OMEN_SIZE = '40px'

const Styles = makeStyles(({ accessibility: { highContrast }}) => ({
    omen: {
        width: OMEN_SIZE,
        height: OMEN_SIZE,
        [highContrast.mediaQuery('active')]: {
            color: 'LinkText'
        },
        '&:hover, &:focus': {
            [highContrast.mediaQuery('active')]: {
                color: 'WindowText'
            },
        }
    },
    socialMediaLinks: {
        display: 'flex',
        alignItems: 'center',
        '& a': {
            width: 'auto',
            textAlign: 'center'
        },
        justifyContent: 'center',
        '& a': {
            width: 'auto',
            marginRight: ITEM_GAP,
            '&:last-of-type': {
                marginRight: 0
            }
        }
    }
}))

export default ({ home }) => {
    const classes = Styles()

    return (
        <nav aria-label='Social media links' className={classes.socialMediaLinks}>
            <Linkedin
                href='https://www.linkedin.com/company/the-coalition-studio/'
                target='_blank'
                rel='noopener noreferrer'
                className={classes.twitterLink}
                FontAwesomeIconProps={{size:"2x"}}
                aria-label='The Coalition Studio LinkedIn'
                title='The Coalition Studio LinkedIn'
            />
            <Twitter
                href='https://twitter.com/CoalitionGears'
                target='_blank'
                rel='noopener noreferrer'
                className={classes.twitterLink}
                FontAwesomeIconProps={{size:"2x"}}
                aria-label='The Coalition Studio Twitter'
                title='The Coalition Studio Twitter'
            />
            {home && (
                <MenuItem href={process.env.GATSBY_FRANCHISE_SITE_URL} classes={classes.logoLink} title="Gears of War">
                    <Icon className={classes.omen} iconBase='gears' iconPrefix='tcr' iconName='omen'  />
                </MenuItem>
            )}
        </nav>
    )
}